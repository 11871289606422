import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page Contact */
class Contact extends Component {

    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="contact">
                    <div className="contact-wrapper">

                        <h2>Контакты</h2>

                        <div className="mobile-contact">
                            <div className="mobile-contact-item">

                                <h3>г. Атырау</h3>

                                <p>Для партеров</p>
                                <b>+7 (775) 597-00-96</b>

                                <p>Отдел продаж:</p>
                                <b>+7 (702) 197-67-19</b>

                                <p>Вопросы, отзывы и предложения:</p>
                                <b>info@eco-market.me</b>

                                <div className="mobile-contact-line" />

                                <p>Магазин (Нурсая):</p>
                                <b>микрорайон Нурсая, 9</b>

                                <p>Магазин (Жумыскер):</p>
                                <b>микрорайон Жумыскер, ул. 43, 121</b>

                            </div>
                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default Contact