import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { Checkout } from '../widgets/Store'



/* Page Order */
class Order extends Component {

    render = () => {
        return (
            <Block>
                <Checkout />
            </Block>
        )
    }
}

export default Order