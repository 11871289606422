import React, { Component } from "react"

/* Widgets */
import { Settings } from '../../widgets/Profile'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Profile
*/
class MobileProfile extends Component {

    render = () => {

        const { navigate } = this.props

        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <div onClick={() => navigate(-1)} className="account-header-back">
                        <img src="/images/mobile/back.png" alt="Back" />
                    </div>
                    <h2>Редактировать профиль</h2>
                </div>

                <div className="mobile-account-container">
                    <Settings />
                </div>
                
                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileProfile)