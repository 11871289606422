import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { OrderDetail } from '../widgets/Profile'



/* Page Order View */
class OrderView extends Component {

    render = () => {
        return (
            <Block>
                <OrderDetail />
            </Block>
        )
    }

}

export default OrderView