/* Cart REDUX */

const cartJSON = localStorage.getItem("cart")
const DEFAULT = cartJSON ? JSON.parse(cartJSON) : []

const cart = (state = DEFAULT, action) => {

    switch (action.type) {

        case 'ADD_TO_CART':
            const created = [...state, action.payload]
            localStorage.setItem("cart", JSON.stringify(created))
            return created

        case 'REMOVE_FROM_CART':
            const removed = state.filter(item => item.id !== action.payload.id)
            localStorage.setItem("cart", JSON.stringify(removed))
            return removed

        case 'UPDATE_DATA':
            const updated = state.filter(item => item.id !== action.payload.id).concat(action.payload)
            localStorage.setItem("cart", JSON.stringify(updated))
            return updated

        case 'REFRESH_DATA':

            const refreshed = [...state]
            const index = state.findIndex(item => item.id === action.payload.id)

            if (index > -1) {
                refreshed[index] = action.payload
            }

            localStorage.setItem("cart", JSON.stringify(refreshed))
            return refreshed

        case 'CLEAR_CART':
            localStorage.removeItem("cart")
            return []

        default:
            return state
    }
}

export default cart