import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../constants'


/* Widget Categories */
class Categories extends Component {

    render = () => {

        const { data, id } = this.props

        return (
            <div className="home-categories">

                <h2>Категория</h2>
                <div className="home-category-line" />

                <div className="home-category-box">
                    {Array.isArray(data) && data.length > 0 && data.map((category, index) =>
                        <Link to={`/category/${category.slug}`} key={`${index}`} className={(id && parseInt(id) > 0 && parseInt(id) === parseInt(category.id)) ? "home-category-item active" : "home-category-item"}>
                            {category.image ? <img src={`${env.mediapoint}categories/${category.image}`} alt={category.name} /> : null}
                            <span>{category.name}</span>
                        </Link>
                    )}
                </div>

                <div className="home-category-link">
                    <Link>Ценность дня</Link>
                    <Link>50 лучших предложений</Link>
                    <Link>Новые поступления</Link>
                </div>

            </div>
        )
    }

}

export default Categories