import React, { Component } from "react"

/* Widgets */
import { ChangePassword } from '../../widgets/Profile'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Password
*/
class MobilePassword extends Component {

    render = () => {

        const { navigate } = this.props

        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <div onClick={() => navigate(-1)} className="account-header-back">
                        <img src="/images/mobile/back.png" alt="Back" />
                    </div>
                    <h2>Изменить пароль</h2>
                </div>

                <div className="mobile-account-container">
                    <ChangePassword />
                </div>
                
                <Menu />
            </div>
        )
    }

}

export default withRouter(MobilePassword)