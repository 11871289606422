import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { Likes } from '../widgets/Favoutrite'



/* Page Favourite */
class Favourite extends Component {

    render = () => {
        return (
            <Block>
                <Likes />
            </Block>
        )
    }
}

export default Favourite