import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { AddressDetail } from '../widgets/Profile'



/* Page Address */
class Address extends Component {

    render = () => {
        return (
            <Block>
                <AddressDetail />
            </Block>
        )
    }

}

export default Address