import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page About */
class About extends Component {


    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="about">
                    <div className="about-wrapper">
                        <h1>О магазине</h1>


                        <div className="about-box">

                            <div className="about-box-text">

                                <p>Добро пожаловать в наш супермаркет, который был открыт 8 сентября 2021 года! Мы гордимся тем, что можем предложить вам высокое качество товаров по низким ценам. У нас вы найдете огромный ассортимент продуктов и товаров для всех ваших потребностей.</p>

                                <p>В нашем супермаркете вы можете насладиться широким выбором товаров, начиная от свежих продуктов питания и органических продуктов до бытовой техники и товаров для дома. Независимо от того, что вы ищете, у нас есть все, чтобы удовлетворить ваши требования.</p>

                                <p>Мы гордимся нашими низкими ценами, которые доступны для всех. Мы стремимся сделать покупки доступными каждому, и поэтому мы поддерживаем конкурентоспособные цены на все наши товары.</p>


                                <p>В нашем супермаркете мы также ценим каждого нашего клиента. Наш дружелюбный и приветливый персонал всегда готов помочь вам с выбором товаров или ответить на ваши вопросы. Мы стараемся создать приятную и комфортную атмосферу для всех наших посетителей.</p>

                                <p>Кроме того, мы гордимся оперативным обслуживанием. Мы стремимся обеспечить быструю и эффективную обработку всех покупок, чтобы вы могли сэкономить время и насладиться своими покупками.</p>

                                <p>Посетите наш супермаркет, чтобы оценить все преимущества, которые мы предлагаем. Мы уверены, что вы останетесь довольны нашим широким выбором товаров, доступными ценами, вежливым персоналом и оперативным обслуживанием. Ждем вас каждый день без выходных!</p>

                            </div>

                            <img src="/images/about.jpg" alt="About" />

                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default About