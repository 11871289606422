/* CITY REDUX */
const cityJSON = localStorage.getItem("city")

if (cityJSON && cityJSON.length < 20) {
    localStorage.removeItem("city")
}

const DEFAULT = cityJSON && cityJSON.length > 20 ? JSON.parse(cityJSON) : null

const city = (state = DEFAULT, action) => {

    switch (action.type) {

        case 'SET_CITY':
            localStorage.setItem("city", JSON.stringify(action.payload))
            return action.payload

        default:
            return state
    }
}

export default city