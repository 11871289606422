import React, { Component } from "react"

/* Widgets */
import { Basket } from '../../widgets/Store'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Cart
*/
class MobileCart extends Component {

    render = () => {

        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <h2>Корзина</h2>
                </div>

                <div className="mobile-account-container">
                    <Basket />
                </div>
                
                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileCart)