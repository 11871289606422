import React, { Component } from "react"

/* Components */
import { Loading, Network, Empty } from '../../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { withRouter } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Widgets */
import Menu from "./Menu"

/* JWT */
import { isExpired } from "react-jwt"

/* REST API */
import { list, create, edit, remove } from '../../api/Address'
import { list as city } from '../../api/City'

/* Modal */
import Modal from 'react-modal'

/* Swal */
import Swal from 'sweetalert2'



/* Widget Address Detail */
class AddressDetail extends Component {


    constructor() {
        super()

        this.state = {
            data: [],

            loading: true,
            network: false,
            error: false,
            waiting: false,

            createModal: false,
            update: false,
            name: '',
            address: '',
            cityID: 0,
            flat: '',
            floor: '',
            entrance: '',
            id: 0,

            cities: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Address */
    load = () => {
        const { token, navigate } = this.props

        if (!token || isExpired(token)) {
            navigate('/')
        }
        else {
            list(token).then(response => {
                if (response.status === 200) {
                    this.setState({ data: response.data, error: false, network: false })
                }
                else {
                    this.setState({ error: true })
                }
            }).catch(() => {
                this.setState({ error: true })
            }).finally(() => {
                this.setState({ loading: false })
            })

            city(token).then(response => {
                if (response.status === 200) {
                    this.setState({ cities: response.data })
                }
            })
        }
    }





    /* Draw Address Modal */
    _create = () => {

        const { createModal, cities, name, address, cityID, flat, floor, entrance, waiting } = this.state

        return (
            <Modal
                isOpen={createModal}
                onRequestClose={() => this.setState({ createModal: false })}
                className="address-modal"
                overlayClassName="address-overlay"
            >

                <div onClick={() => this.setState({ createModal: false })} className="address-modal-close">
                    <img src="/images/x.png" alt="X" />
                </div>

                <div className="address-modal-form">
                    <h2>Добавить адрес</h2>

                    <p>Название</p>
                    <input type="text" value={name} onChange={event => this.setState({ name: event.target.value })} placeholder="Введите название (Дом, Работа и т.д.)" />

                    <p>Город</p>
                    <select value={cityID} onChange={event => this.setState({ cityID: event.target.value })}>
                        <option value="0">Не выбран</option>
                        {cities.map((item, index) =>
                            <option selected={parseInt(cityID) === parseInt(item.id)} key={`${index}`} value={item.id}>{item.name}</option>
                        )}
                    </select>

                    <p>Улица, дом</p>
                    <input type="text" value={address} onChange={event => this.setState({ address: event.target.value })} placeholder="Введите улицу и дом" />

                    <div className="address-modal-row">

                        <div className="address-modal-form-box">
                            <p>Квартира</p>
                            <input type="text" value={flat} onChange={event => this.setState({ flat: event.target.value })} placeholder="Номер квартиры" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Этаж</p>
                            <input type="text" value={floor} onChange={event => this.setState({ floor: event.target.value })} placeholder="Этаж" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Подъезд</p>
                            <input type="text" value={entrance} onChange={event => this.setState({ entrance: event.target.value })} placeholder="Подъезд" />
                        </div>

                    </div>

                    <div onClick={() => this.create()} className="address-modal-button">
                        {waiting ? <LoadingOutlined /> : "Сохранить"}
                    </div>
                </div>

            </Modal>
        )
    }


    create = () => {

        const { data, name, address, cityID, flat, floor, entrance } = this.state
        const { token } = this.props

        if (name === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название адреса', showConfirmButton: false, timer: 1500 })
            return
        }

        if (cityID === null || cityID === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите город', showConfirmButton: false, timer: 1500 })
            return
        }

        if (address === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите адрес', showConfirmButton: false, timer: 1500 })
            return
        }


        const body = { name, address, cityID, flat, floor, entrance }
        this.setState({ waiting: true })

        create(token, body).then(response => {
            if (response.status === 200) {

                data.push(response.data)

                this.setState({
                    data,
                    name: '',
                    address: '',
                    cityID: 0,
                    flat: '',
                    floor: '',
                    entrance: '',
                    createModal: false
                })

                Swal.fire({ icon: 'success', title: 'Адрес успешно создан', showConfirmButton: false, timer: 1500 })
            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        }).finally(() => {
            this.setState({ waiting: false })
        })
    }




    /* Draw Address Modal */
    _update = () => {

        const { id, updateModal, cities, name, address, cityID, flat, floor, entrance } = this.state

        return (
            <Modal
                isOpen={updateModal}
                onRequestClose={() => this.setState({ name: '', address: '', cityID: 0, flat: '', floor: '', entrance: '', updateModal: false })}
                className="address-modal"
                overlayClassName="address-overlay"
            >

                <div onClick={() => this.setState({ name: '', address: '', cityID: 0, flat: '', floor: '', entrance: '', updateModal: false })} className="address-modal-close">
                    <img src="/images/x.png" alt="X" />
                </div>

                <div className="address-modal-form">
                    <h2>Редактировать адрес</h2>

                    <p>Название</p>
                    <input type="text" value={name} onChange={event => this.setState({ name: event.target.value })} placeholder="Введите название (Дом, Работа и т.д.)" />

                    <p>Город</p>
                    <select value={cityID} onChange={event => this.setState({ cityID: event.target.value })}>
                        <option disabled value={0}>Не выбран</option>
                        {cities.map((item, index) =>
                            <option selected={parseInt(cityID) === parseInt(item.id)} key={`${index}`} value={item.id}>{item.name}</option>
                        )}
                    </select>

                    <p>Улица, дом</p>
                    <input type="text" value={address} onChange={event => this.setState({ address: event.target.value })} placeholder="Введите улицу и дом" />

                    <div className="address-modal-row">

                        <div className="address-modal-form-box">
                            <p>Квартира</p>
                            <input type="text" value={flat} onChange={event => this.setState({ flat: event.target.value })} placeholder="Номер квартиры" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Этаж</p>
                            <input type="text" value={floor} onChange={event => this.setState({ floor: event.target.value })} placeholder="Этаж" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Подъезд</p>
                            <input type="text" value={entrance} onChange={event => this.setState({ entrance: event.target.value })} placeholder="Подъезд" />
                        </div>

                    </div>

                    <div className="address-modal-buttons">
                        <div onClick={() => this.remove(id)} className="address-modal-button address-modal-button-remove">Удалить</div>
                        <div onClick={() => this.update()} className="address-modal-button address-modal-button-save">Сохранить</div>
                    </div>
                </div>

            </Modal>
        )
    }


    /* REMOVE */
    remove = id => {

        const { token } = this.props
        const { data } = this.state

        Swal.fire({
            title: 'Удалить адрес?',
            text: 'Вы дейсвительно хотите удалить адрес?',
            icon: 'warning',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Отменить',
            showCancelButton: true,
            confirmButtonColor: '#FF4F4F',
            reverseButtons: true
        }).then(result => {
            if (result.isConfirmed) {
                remove(token, { id }).then(response => {
                    if (response.status === 200) {

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        this.setState({
                            id: 0,
                            name: '',
                            address: '',
                            cityID: 0,
                            flat: '',
                            floor: '',
                            entrance: '',
                            updateModal: false
                        })


                        Swal.fire({ icon: 'success', title: 'Адрес успешно изменен', showConfirmButton: false, timer: 1500 })
                    }
                    else {
                        Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
                    }
                }).catch(() => {
                    Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
                })

            }
        })
    }


    /* UPDATE */
    update = () => {

        const { data, id, name, address, cityID, flat, floor, entrance } = this.state
        const { token } = this.props

        if (parseInt(id) > 0) {

            if (name === '') {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название адреса', showConfirmButton: false, timer: 1500 })
                return
            }

            if (cityID === null || cityID === 0) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите город', showConfirmButton: false, timer: 1500 })
                return
            }

            if (address === '') {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите адрес', showConfirmButton: false, timer: 1500 })
                return
            }


            const body = { id, name, address, cityID, flat, floor, entrance }
            this.setState({ waiting: true })

            edit(token, body).then(response => {
                if (response.status === 200) {

                    const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                    if (index > -1) {
                        data[index].name = name
                        data[index].address = address
                        data[index].cityID = cityID
                        data[index].flat = flat
                        data[index].floor = floor
                        data[index].entrance = entrance

                        this.setState({ data })
                    }

                    this.setState({
                        id: 0,
                        name: '',
                        address: '',
                        cityID: 0,
                        flat: '',
                        floor: '',
                        entrance: '',
                        updateModal: false
                    })

                    Swal.fire({ icon: 'success', title: 'Адрес успешно изменен', showConfirmButton: false, timer: 1500 })
                }
                else {
                    Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
                }
            }).catch(() => {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }).finally(() => {
                this.setState({ waiting: false })
            })
        }
    }



    render = () => {

        const { data, loading, network, error } = this.state

        if (loading) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="address" />
                            <div className="profile-box">
                                <Loading />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (network) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="address" />
                            <div className="profile-box">
                                <Network reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (error) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="address" />
                            <div className="profile-box">
                                <Network error reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


        if (data.length === 0) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="address" />
                            <div className="profile-box">
                                <Empty page='address' open={() => this.setState({ createModal: true })} />
                            </div>
                        </div>
                    </div>
                    {this._create()}
                </div>
            )
        }


        return (
            <div className="profile">
                <div className="profile-wrapper">
                    <div className="profile-container">

                        <Menu page="address" />

                        <div className="profile-box">
                            <div className="profile-form">

                                <h2>Мои адреса</h2>

                                {data.map((item, index) =>
                                    <div key={`${index}`} className="profile-address-item">
                                        <div className="profile-address-item-text">
                                            <b>{item.name}</b>
                                            <p>
                                                {item.address}
                                                {item.flat ? ` кв./офис ${item.flat},` : ''}
                                                {item.floor ? ` этаж ${item.floor},` : ''}
                                                {item.entrance ? ` подъезд ${item.entrance}` : ''}
                                            </p>
                                        </div>
                                        <div onClick={() => this.setState({ id: item.id, name: item.name, address: item.address, cityID: item.cityID, flat: item.flat, floor: item.floor, entrance: item.entrance, updateModal: true })} className="profile-address-item-edit">
                                            <img src="/images/edit.png" alt="Edit" />
                                        </div>
                                    </div>
                                )}

                                <div className="profile-space" />

                                <div onClick={() => this.setState({ createModal: true })} className="profile-form-button">
                                    Добавить адрес доставки
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {this._create()}
                {this._update()}

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

export default connect(mapStateToProps)(withRouter(AddressDetail))