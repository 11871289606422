import axios from "axios"
import { env } from '../constants'


/* LIST */
export const list = (parent = '') => {

    let params = ''

    if (parent !== '') {
        params = `?parent=${parent}`
    }

    const uri = `${env.startpoint}category/list${params}`

    return axios.get(uri).then(response => response).catch(error => error.response)
}