import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Constants */
import { env } from "../constants"



/*
    Widget Header
*/
class Header extends Component {

    constructor(props) {
        super(props)

        const { text } = props
        let search = ''
        if (text) {
            search = text
        }

        this.state = {
            search
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.sticky)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.sticky)
    }


    /* Scroll Event Listener */
    sticky = () => {
        const header = document.querySelector('.header-box')
        const top = document.querySelector('.header-top')
        const scrollTop = window.scrollY
        scrollTop >= 40 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky')
        scrollTop >= 40 ? top.classList.add('header-big') : top.classList.remove('header-big')
    }


    /* Draw Top Part */
    _top = () => {

        const { city, cities, setCity } = this.props

        return (
            <div className="header-top">
                <div className="header-top-wrapper">

                    {/* MOBILE LOGO */}
                    <div className="mobile-logo">
                        <img src="/images/logo-white.png" alt="Logo" />
                    </div>

                    {/* HEADER CITY */}
                    {city &&
                        <div className="header-top-city">
                            <img src="/images/mobile/pin.png" alt="Location" />
                            <span>{city.name}</span>

                            <div className="header-top-city-list">
                                {cities.map((item, index) =>
                                    <div onClick={() => setCity(item)} key={`${index}`} className={parseInt(item.id) === parseInt(city.id) ? "header-top-city-item active" : "header-top-city-item"}>{item.name}</div>
                                )}
                            </div>

                        </div>
                    }

                    {/* SHOP ADDRESS */}
                    <div className="header-top-address">
                        Казахстан, г.Атырау, микрорайон Нурсая, 9
                    </div>

                    {/* LANGUAGE */}
                    <div className="header-top-language">
                        <img src="/images/ru.png" alt="Flag" />
                        <span>Русский</span>
                    </div>

                </div>
            </div>
        )
    }


    /* Go To Cart */
    goCart = () => {
        const { cart, navigate } = this.props

        if (cart.length > 0) {
            navigate("/cart")
        }
    }


    /* Go To User */
    goUser = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/profile')
        }
    }

    /* Go To Favourite */
    goFavourite = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/favourite')
        }
    }

    /* Go To Search */
    goSearch = () => {
        const { navigate } = this.props
        const { search } = this.state

        if (search !== "") {
            navigate(`/search/${search}`)
        }
    }


    render = () => {

        const { cart, token, city, cities, setCity } = this.props
        const user = token ? decodeToken(token) : null

        const { search } = this.state

        return (
            <div className="header">

                {this._top()}

                <div className="header-box">
                    <div className="header-wrapper">

                        <div className="header-left-box">

                            {/* HEADER LOGO */}
                            <div className="header-logo">
                                <Link to="/">
                                    <img src="/images/logo.png" alt="Ecomarket" />
                                </Link>
                            </div>

                            {/* HEADER CITY */}
                            {city &&
                                <div className="header-city">
                                    <img src="/images/location.png" alt="Location" />
                                    <span>{city.name}</span>

                                    <div className="header-city-list">
                                        {cities.map((item, index) =>
                                            <div onClick={() => setCity(item)} key={`${index}`} className={parseInt(item.id) === parseInt(city.id) ? "header-city-item active" : "header-city-item"}>{item.name}</div>
                                        )}
                                    </div>

                                </div>
                            }

                            {/* HEADER SEARCH */}
                            <div className="header-search">
                                <input value={search} onChange={event => this.setState({ search: event.target.value })} placeholder="Искать продукты" />
                                <div onClick={() => this.goSearch()} className="header-search-button">
                                    <img src="/images/search.png" alt="Search" />
                                </div>
                            </div>

                        </div>

                        <div className="header-right-box">

                            {/* HEADER CONTACT INFORMATION */}
                            <div className="header-contact">
                                <img src="/images/phone.png" alt="Phone" />
                                <div className="header-contact-text">
                                    <span>Пн-Вc 09:00 - 22:00</span>
                                    <p>+7 (702) 197-67-19</p>
                                </div>
                            </div>

                            <div className="header-divider" />

                            {/* HEADER FAVOUTRITE */}
                            <div onClick={() => this.goFavourite()} className="header-favourite">
                                <img src="/images/heart.png" alt="Heart" />
                            </div>

                            <div className="header-divider" />

                            {/* HEADER FAVOUTRITE */}
                            <div onClick={() => this.goCart()} className="header-cart">
                                <img src="/images/cart.png" alt="Cart" />
                                {cart.length > 0 && <span>{cart.length}</span>}
                                {cart.length === 0 &&
                                    <div className="header-empty-cart">
                                        <div className="header-cart-corner" />
                                        <img src="/images/empty-cart.png" alt="Empty Cart" />
                                        <div className="header-empty-cart-box">
                                            <h3>Корзина пуста</h3>
                                            <p>Добавьте что-то из категории для оформления заказа</p>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="header-divider" />

                            {/* HEADER USER */}
                            <div onClick={() => this.goUser()} className="header-user">

                                {(user && user.image) ? <img src={`${env.mediapoint}users/${user.image}`} alt="user" className="ava" /> : <img src="/images/user.png" alt="user" />}

                                {user
                                    ? <div className="header-user-text">
                                        <span>Привет</span>
                                        <p>{user.name}</p>
                                    </div>
                                    : <div className="header-user-text">
                                        <span>Мой аккаунт</span>
                                        <p>Войти</p>
                                    </div>
                                }
                            </div>

                        </div>


                    </div>
                </div>

            </div >
        )
    }

}


const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token,
        cities: state.cities,
        city: state.city,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_MODAL', payload: data }),
        setCity: data => dispatch({ type: 'SET_CITY', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))