import React, { Component } from "react"

/* Components */
import { Loading, Network } from '../../components'

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* REST API */
import { view } from '../../api/Order'
import { create } from '../../api/Review'

/* JWT */
import { isExpired } from "react-jwt"

/* Constants */
import { env } from '../../constants'

/* Swal */
import Swal from "sweetalert2"



/* Fields */
const GOOD = ["Сервис со стороны курьера", "Цена", "Скорость доставки", "Супер сайт"]
const BAD = ["Испорчена упаковка", "Не устроил курьер", "Не удобный сайт", "Опаздали с доставкой", "Неверно собран заказ"]



/* Widget Order Detail */
class OrderDetail extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            loading: true,
            network: false,
            error: false,
            rate: 0,
            reasons: [],
            comment: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Order View Data */
    load = () => {

        const { params, token, navigate } = this.props

        if (!token || isExpired(token)) {
            navigate('/')
        }
        else {
            view(token, { number: params.number }).then(response => {
                if (response.status === 200) {
                    this.setState({ data: response.data, error: false, network: false })
                }
                else {
                    this.setState({ error: true })
                }
            }).catch(() => {
                this.setState({ network: true })
            }).finally(() => {
                this.setState({ loading: false })
            })
        }
    }


    send = () => {

        const { token } = this.props
        const { data, rate, reasons, comment } = this.state

        create(token, { orderID: data.id, rate, reasons: JSON.stringify(reasons), comment }).then(response => {
            if (response.status === 200) {

                data.rate = rate
                data.review = comment
                data.reasons = JSON.stringify(reasons)
                this.setState({ data })

                Swal.fire({
                    title: "Оценка успешно отправлено",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }


    /* Toggle Reason */
    toggleReason = reason => {
        const { reasons } = this.state

        const index = reasons.findIndex(e => e === reason)

        if (index > -1) {
            reasons.splice(index, 1)
        }
        else {
            reasons.push(reason)
            this.setState({ reasons })
        }
    }


    /* Find Reason */
    findReason = reason => {

        const { reasons } = this.state

        const index = reasons.findIndex(e => e === reason)

        if (index > -1) {
            return true
        }

        return false
    }


    render = () => {

        const { data, loading, network, error, rate, comment } = this.state
        const { navigate } = this.props

        if (loading) {
            return <Loading />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }

        if (error || !data) {
            return <Network error reload={() => this.load()} />
        }


        const products = data.products ? JSON.parse(data.products) : []
        const reasons = rate >= 4 ? GOOD : BAD


        return (
            <div className="order-view">
                <div className="order-view-wrapper">

                    <div className="order-view-title">
                        <div onClick={() => navigate(-1)} className="order-view-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>Заказ №{data.number}</h2>
                    </div>


                    <div className="order-view-box">

                        <div className="order-view-information">
                            <h3>{utils.deliveryStatusText(data.deliveryType, data.deliveryStatus)}</h3>
                            {utils.deliveryStatusView(data.deliveryType, data.deliveryStatus)}

                            {data.deliveryType === "delivery" &&
                                <div className="order-view-address">
                                    <h3>Адрес доставки</h3>
                                    <div className="order-view-address-box">
                                        <img src="/images/order/pin.png" alt="Pin" />
                                        <p>
                                            {utils.check(data.address)}
                                            {data.flat ? `, кв./офис ${data.flat}` : ''}
                                            {data.floor ? `, этаж ${data.floor}` : ''}
                                            {data.entrance ? `, подьезд ${data.entrance}` : ''}
                                        </p>
                                    </div>
                                </div>
                            }

                            {data.deliveryType === "pickup" &&
                                <div className="order-view-address">
                                    <h3>Способ доставки</h3>
                                    <p>Самовывоз</p>
                                </div>
                            }

                            <div className="order-view-address">
                                <h3>Способ оплаты</h3>
                                <p>Наличными</p>
                            </div>

                            <div className="order-view-products">
                                {products.map((product, index) =>
                                    <div className="order-view-product" key={`${index}`}>
                                        <div className="order-view-product-info">
                                            <img src={`${env.mediapoint}products/${product.image}`} alt="Product" />
                                            <div className="order-view-product-text">
                                                <div className="order-view-product-name">{product.product}</div>
                                                <div className="order-view-product-amount">{product.amount} шт</div>
                                            </div>
                                        </div>
                                        <div className="order-view-product-price-box">
                                            <div className="order-view-product-price">
                                                {parseInt(product.sale) > 0 ? utils.convertor(parseInt(product.sale) * parseInt(product.amount)) : utils.convertor(parseInt(product.price) * parseInt(product.amount))}
                                            </div>
                                            <div className="order-view-product-sale-price">
                                                {parseInt(product.sale) > 0 ? utils.convertor(parseInt(product.price) * parseInt(product.amount)) : ""}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="order-view-product-row">
                                    <p>Доставка:</p>
                                    <b>{utils.convertor(data.delivery)}</b>
                                </div>

                                <div className="order-view-product-row">
                                    <b>Итого:</b>
                                    <b>{utils.convertor(data.total)}</b>
                                </div>

                            </div>

                        </div>


                        {(data.rate && parseInt(data.rate) > 0)
                            ?
                            <div className="order-view-review">
                                <h3>Ваша оценка</h3>
                                <p>Спасибо за оценку!</p>

                                <div className="order-view-reviewed-stars">
                                    {[1, 2, 3, 4, 5].map((item, index) =>
                                        <div key={`${index}`}>
                                            {item <= parseInt(data.rate)
                                                ? <img src="/images/order/star.png" alt="Star" />
                                                : <img src="/images/order/star-gray.png" alt="Star" />
                                            }
                                        </div>
                                    )}
                                </div>

                                <div className="order-view-reviewed-reasons">
                                    {data.reasons !== null && JSON.parse(data.reasons).map((item, index) =>
                                        <div key={`${index}`}>{item}</div>
                                    )}
                                </div>

                                {(data.review !== null && data.review !== "" && data.review !== undefined) &&
                                    <div>
                                        <b>Комментарий</b>
                                        <p>{data.review}</p>
                                    </div>
                                }

                            </div>
                            :
                            <div className="order-view-review">
                                <h3>Оценить заказ</h3>
                                <p>Нам важно Ваше мнение </p>

                                <div className="order-view-review-stars">
                                    {[1, 2, 3, 4, 5].map((item, index) =>
                                        <div onClick={() => this.setState({ rate: item, reasons: [] })} key={`${index}`}>
                                            {item <= rate
                                                ? <img src="/images/order/star.png" alt="Star" />
                                                : <img src="/images/order/star-gray.png" alt="Star" />
                                            }
                                        </div>
                                    )}
                                </div>

                                {rate > 0 &&
                                    <div className="order-view-review-text">
                                        <span>{rate >= 4 ? "Спасибо! Что понравилось?" : "Что пошло не так?"}</span>
                                        <div className="order-view-review-reasons">
                                            {reasons.map((item, index) => {
                                                const style = this.findReason(item) ? "order-view-review-reason active" : "order-view-review-reason"
                                                return <div onClick={() => this.toggleReason(item)} key={`${index}`} className={style}>{item}</div>
                                            })}
                                        </div>
                                        <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Комментарий"></textarea>
                                        <div onClick={() => this.send()} className="order-view-review-button">Отправить</div>
                                    </div>
                                }

                            </div>
                        }

                    </div>



                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

export default connect(mapStateToProps)(withRouter(OrderDetail))