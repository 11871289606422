import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { CategoryProducts } from '../widgets/Store'



/* Page Category */
class Category extends Component {

    render = () => {
        return (
            <Block>
                <CategoryProducts />
            </Block>
        )
    }
}

export default Category