/* FAVOURITE REDUX */

const favourites = (state = [], action) => {

    switch (action.type) {


        case 'INIT_FAVOURITE':
            return action.payload

        case 'ADD_FAVOURITE':
            return [...state, action.payload]

        case 'REMOVE_FAVOURITE':
            return state.filter(item => item.id !== action.payload.id)

        default:
            return state
    }
}

export default favourites