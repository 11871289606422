import React, { Component } from "react"

/* Widgets */
import { Likes } from '../../widgets/Favoutrite'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Favourite
*/
class MobileFavourite extends Component {

    render = () => {

        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <h2>Избранное</h2>
                </div>

                <div className="mobile-account-container">
                    <Likes />
                </div>
                
                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileFavourite)