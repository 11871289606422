const point = "https://back.eco-market.me"
const link = "https://eco-market.me/"

/*const point = "http://localhost:8000"
const link = "https://eco-market.me/"*/

/*const startpoint = "http://localhost:8000/api/v1/"
const mediapoint = "http://localhost:8000/"*/


const startpoint = `${point}/api/v1/`
const mediapoint = `${point}/`

export { startpoint, mediapoint, point, link }