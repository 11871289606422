import React, { Component } from "react"

/* Components */
import { Loading, Network } from "../../components"

/* Widgets */
import { Product } from '../Product'

/* REST API */
import { search } from '../../api/Product'

/* Helpers */
import { withRouter } from '../../helpers'



/* Widget Find */
class Find extends Component {

    constructor() {
        super()

        this.state = {

            data: [],

            loading: true,
            error: false,
            network: false
        }
    }

    componentDidMount = () => {
        this.load()
    }


    componentDidUpdate = (prevProps) => {

        const { params } = this.props

        if (params.slug !== prevProps.params.slug) {
            this.load(params.slug)
        }
    }


    load = (slug = '') => {

        const { params } = this.props

        search({ slug: slug ? slug : params.slug }).then(response => {
            console.log(response.data)
            if (response.status === 200) {
                this.setState({ data: response.data, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }



    render = () => {

        const { loading, error, network, data } = this.state
        const { navigate, params } = this.props


        if (loading) {
            return <Loading />
        }

        if (error) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }

        return (
            <div className="search">
                <div className="search-wrapper">

                    <div className="search-title">
                        <div onClick={() => navigate(-1)} className="search-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>Поиск по запросу "{params.slug}"</h2>
                    </div>

                    <div className="search-box">
                        {data.map((item, index) =>
                            <div className="search-product" key={`${index}`}>
                                <Product data={item} />
                            </div>
                        )}
                        {data.length === 0 && <div className="search-products-empty">По вашему запросу ничего не найдено</div>}
                    </div>

                </div>
            </div>
        )
    }

}

export default withRouter(Find)