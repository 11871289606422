import React, { Component } from "react"

/* Components */
import { Loading, Network } from "../../components"

/* Widgets */
import { Categories } from '../Home'
import { Product } from '../Product'

/* REST API */
import { category, withCategory } from '../../api/Product'

/* Helpers */
import { withRouter } from '../../helpers'

/* Router Dom */
import { Link } from "react-router-dom"

/* Constants */
import { env } from "../../constants"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* 
    Widget Category Products
*/
class CategoryProducts extends Component {

    constructor() {
        super()

        this.state = {

            data: null,

            loading: true,
            error: false,
            network: false,

            page: 1,
            waiting: false,
            ended: false,

            products: [],
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {

        const { params } = this.props

        if (params.slug !== prevProps.params.slug) {
            this.load(params.slug)
        }
    }


    load = (slug = '') => {

        const { params } = this.props

        category({ slug: slug ? slug : params.slug }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, network: false, error: false, products: response.data.products })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    add = page => {

        const { data, products } = this.state

        this.setState({ page, waiting: true })

        withCategory({ id: data.category.id, page }).then(response => {
            if (response.status === 200) {
                if (response.data.length === 0) {
                    this.setState({ ended: true })
                }
                this.setState({ products: [...products, ...response.data], waiting: false })
            }
        })
    }


    render = () => {

        const { loading, error, network, data, products, page, waiting, ended } = this.state
        const { navigate, isMobile } = this.props

        if (loading) {
            return <Loading />
        }

        if (error) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }


        const content = (
            <div className="category">
                <div className="category-wrapper">

                    <div className="category-title">
                        <div onClick={() => navigate(-1)} className="category-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>{data.category.name}</h2>
                    </div>

                    <div className="category-box">

                        <Categories id={data.category.id} data={data.categories} />

                        <div className="category-products-container">

                            <div className="category-products-children">
                                {data.children !== undefined && data.children !== null && Array.isArray(data.children) && data.children.length > 0 && data.children.map((item, index) =>
                                    <Link to={`/category/${item.slug}`} className="category-children" key={`${index}`}>
                                        {item.image ? <img src={`${env.mediapoint}categories/${item.image}`} alt={item.name} /> : ""}
                                        <span>{item.name}</span>
                                    </Link>
                                )}
                            </div>


                            <div className="category-products">

                                {products.map((item, index) =>
                                    <div className="category-product" key={`${index}`}>
                                        <Product data={item} />
                                    </div>
                                )}

                                {(products.length === 0 && data.children.length === 0) && <div className="category-products-empty">Пока здесь пусто</div>}

                            </div>

                            {ended === false &&
                                <div className="add-bottom">
                                    <div onClick={() => this.add(page + 1)} className="add-more">
                                        {waiting ? <LoadingOutlined /> : "Загрузить еще"}
                                    </div>
                                </div>
                            }

                        </div>


                    </div>

                </div>
            </div>
        )


        if (isMobile) {
            return (
                <div>
                    <div className="account-header">
                        <div onClick={() => navigate(-1)} className="account-header-back">
                            <img src="/images/mobile/back.png" alt="Back" />
                        </div>
                        <h2>{data.category.name}</h2>
                    </div>

                    <div className="mobile-account-container">
                        {content}
                    </div>
                </div>
            )
        }


        return content
    }

}

export default withRouter(CategoryProducts)