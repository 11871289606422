import React, { Component } from "react"

/* Widgets */
import { Find } from '../../widgets/Store'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Search
*/
class MobileSearch extends Component {

    constructor(props) {
        super(props)

        const { params } = this.props
        const text = params.slug ? params.slug : ''

        this.state = {
            search: text
        }
    }


    /* Go To Search */
    goSearch = () => {
        const { navigate } = this.props
        const { search } = this.state

        if (search !== "") {
            navigate(`/category/search/${search}`)
        }
    }
    

    render = () => {

        const { navigate } = this.props
        const { search } = this.state
        
        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <div onClick={() => navigate(-1)} className="account-header-back">
                        <img src="/images/mobile/back.png" alt="Back" />
                    </div>
                    <h2>Поиск</h2>
                </div>

                <div className="mobile-account-container">

                    {/* HEADER SEARCH */}
                    <div className="mobile-category-search">
                        <input value={search} onChange={event => this.setState({ search: event.target.value })} placeholder="Искать продукты" />
                        <div onClick={() => this.goSearch()} className="mobile-category-search-button">
                            <img src="/images/search.png" alt="Search" />
                        </div>
                    </div>

                    <Find />
                </div>

                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileSearch)