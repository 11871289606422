import axios from 'axios'
import { env } from '../constants'


/* VIEW */
export const view = data => {
    const uri = `${env.startpoint}product/get`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* CATEGORY */
export const category = data => {
    const uri = `${env.startpoint}product/category`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* CATEGORY */
export const withCategory = data => {
    const uri = `${env.startpoint}product/with-category`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* SEARCH */
export const search = data => {
    const uri = `${env.startpoint}product/search`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}