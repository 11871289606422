import React, { Component } from "react"

/* Widgets */
import { Checkout } from '../../widgets/Store'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Checkout
*/
class MobileCheckout extends Component {

    render = () => {

        const { navigate } = this.props
        
        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <div onClick={() => navigate(-1)} className="account-header-back">
                        <img src="/images/mobile/back.png" alt="Back" />
                    </div>
                    <h2>Оформление заказа</h2>
                </div>

                <div className="mobile-account-container">
                    <Checkout />
                </div>

                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileCheckout)