import React, { Component } from "react"

/* Constants */
import { env } from '../../constants'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Icons */
import { DeleteOutlined } from '@ant-design/icons'

/* Swal */
import Swal from 'sweetalert2'

/* JWT */
import { isExpired } from "react-jwt"

/* Components */
import { Empty } from "../../components"


/* Widget Basket */
class Basket extends Component {


    constructor() {
        super()

        this.state = {
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }


    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }


    /* Clear action */
    clear = () => {

        const { clear } = this.props

        Swal.fire({
            title: 'Очистить корзину?',
            text: 'Вы дейсвительно хотите очистить корзину?',
            icon: 'warning',
            confirmButtonText: 'Да, очистить!',
            cancelButtonText: 'Отменить',
            showCancelButton: true,
            confirmButtonColor: '#0DA487',
            reverseButtons: true
        }).then(result => {
            if (result.isConfirmed) {
                clear()
                Swal.fire({
                    html: `<p class="notification">Корзина успешно очищена</p>`,
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'bottom-left',
                    timer: 1000,
                    backdrop: false,
                    width: 300
                })
            }
        })
    }


    /* Remove action */
    remove = item => {
        const { remove } = this.props
        remove(item)
    }

    /* Minus action */
    minus = item => {
        const { refresh } = this.props
        const count = parseInt(item.amount) - 1

        if (count > 0) {
            const data = { ...item, amount: count }
            refresh(data)
        }
    }

    /* Plus action */
    plus = item => {
        const { refresh } = this.props
        const count = parseInt(item.amount) + 1

        const data = { ...item, amount: count }
        refresh(data)
    }


    /* Go To Order */
    goOrder = () => {

        const { token, toggle, navigate } = this.props
        const { width } = this.state

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            if (width > 768) {
                navigate('/order')
            }
            else {
                navigate('/cart/order')
            }
        }

    }


    /* Draw total */
    _total = () => {

        const { cart } = this.props

        let total = 0

        cart.forEach(item => {
            const price = (item.salePrice && parseInt(item.salePrice) > 0) ? parseInt(item.salePrice) : parseInt(item.price)
            total += price * parseInt(item.amount)
        })

        return (
            <div className="cart-total">
                <h3>Ваш заказ</h3>
                <div className="cart-total-row">
                    <p>Товары ({cart.length})</p>
                    <p>{utils.convertor(total)}</p>
                </div>
                <div className="cart-total-row">
                    <b>Итого</b>
                    <b>{utils.convertor(total)}</b>
                </div>
                <div onClick={() => this.goOrder()} className="cart-total-button">Перейти к оформлению</div>
            </div>
        )
    }


    render = () => {

        const { cart, navigate } = this.props

        if (cart.length === 0) {
            return <Empty page="cart" />
        }


        return (
            <div className="cart">
                <div className="cart-wrapper">

                    <div className="cart-title">
                        <div onClick={() => navigate(-1)} className="cart-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>Корзина</h2>
                    </div>

                    <div className="cart-box">

                        <div className="cart-products">

                            <div className="cart-clear">
                                <div className="cart-clear-button" onClick={() => this.clear()}>Удалить все</div>
                            </div>

                            <div>
                                {cart.map((item, index) =>
                                    <div key={`${index}`} className="cart-product-item">
                                        <img src={`${env.mediapoint}products/${item.image}`} alt={item.name} />
                                        <div className="cart-product-item-right">
                                            <p>{item.name}</p>
                                            <div className="cart-product-item-controller">
                                                <div className="cart-product-counter">
                                                    <div onClick={() => this.minus(item)} className="cart-product-minus" style={parseInt(item.amount) === 1 ? { opacity: 0.5 } : {}}>
                                                        <img src="/images/minus-filled.png" alt="Minus" />
                                                    </div>
                                                    <div className="cart-product-amount">{item.amount}</div>
                                                    <div onClick={() => this.plus(item)} className="cart-product-plus">
                                                        <img src="/images/plus-filled.png" alt="Plus" />
                                                    </div>
                                                </div>
                                                <div className="cart-product-price-box">
                                                    <div className="cart-product-price">{(item.salePrice && parseInt(item.salePrice) > 0) ? utils.convertor(parseInt(item.salePrice) * parseInt(item.amount)) : utils.convertor(parseInt(item.price) * parseInt(item.amount))}</div>
                                                    <div className="cart-product-sale-price">{(item.salePrice && parseInt(item.salePrice) > 0) ? utils.convertor(parseInt(item.price) * parseInt(item.amount)) : ""}</div>
                                                </div>
                                                <div onClick={() => this.remove(item)} className="cart-product-remove">
                                                    <DeleteOutlined />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {this._total()}

                    </div>

                </div>


                <div className="cart-box-mobile-bottom">
                    <div onClick={() => this.goOrder()} className="cart-box-mobile-button">Перейти к оформлению</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}


const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({ type: 'ADD_TO_CART', payload: data }),
        update: data => dispatch({ type: 'UPDATE_DATA', payload: data }),
        refresh: data => dispatch({ type: 'REFRESH_DATA', payload: data }),
        remove: data => dispatch({ type: 'REMOVE_FROM_CART', payload: data }),
        clear: () => dispatch({ type: 'CLEAR_CART', payload: [] }),
        toggle: () => dispatch({ type: 'TOGGLE_MODAL', payload: [] })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Basket))