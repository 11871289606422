import React, { Component } from "react"

/* Widgets */
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'

/* REST API */
import { list } from '../../api/Category'

/* Components */
import { Loading, Network } from '../../components'

/* Constants */
import { env } from "../../constants"





/* Page Mobile Category */
class MobileCategory extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            loading: true,
            error: false,
            network: false,
            search: '',
            category: null
        }
    }

    componentDidMount = () => {

        const { params } = this.props

        let slug = params.slug ? params.slug : ''
        this.load(slug)

    }


    componentDidUpdate = (prevProps) => {

        const { params } = this.props

        if (params.slug !== prevProps.params.slug) {
            this.load(params.slug)
        }
    }


    /* Load Categories */
    load = (parent = '') => {

        this.setState({ loading: true })

        list(parent).then(response => {
            if (response.status === 200) {
                this.setState({ category: response.data.category, data: response.data.categories, error: false, network: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })

    }


    /* Go To Search */
    goSearch = () => {
        const { navigate } = this.props
        const { search } = this.state

        if (search !== "") {
            navigate(`/category/search/${search}`)
        }
    }

    /* Go To Products */
    goProducts = item => {
        const { navigate } = this.props

        if (parseInt(item.amount) > 0) {
            navigate(`/category/mobile/${item.slug}`)
        }
        else {
            navigate(`/category/mobile/products/${item.slug}`)
        }
    }


    render = () => {

        const { navigate, params } = this.props
        const { loading, error, network, search, data, category } = this.state

        if (loading) {
            return <Loading />
        }

        if (error) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }

        return (
            <div className="mobile-account-page">

                <div className="account-header">

                    {params.slug !== undefined && params.slug !== null &&
                        <div onClick={() => navigate(-1)} className="account-header-back">
                            <img src="/images/mobile/back.png" alt="Back" />
                        </div>
                    }

                    {category !== null ? <h2>{category.name}</h2> : <h2>Категория</h2>}

                </div>

                <div className="mobile-account-container">

                    {/* HEADER SEARCH */}
                    <div className="mobile-category-search">
                        <input value={search} onChange={event => this.setState({ search: event.target.value })} placeholder="Искать продукты" />
                        <div onClick={() => this.goSearch()} className="mobile-category-search-button">
                            <img src="/images/search.png" alt="Search" />
                        </div>
                    </div>

                    <div className="mobile-category-box">
                        {data.map((item, index) =>
                            <div onClick={() => this.goProducts(item)} key={`${index}`} className="mobile-category-item">
                                {item.image ? <img src={`${env.mediapoint}categories/${item.image}`} alt="Category" /> : null }
                                <p>{item.name}</p>
                            </div>
                        )}
                    </div>

                </div>

                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileCategory)