import { configureStore } from '@reduxjs/toolkit'

/* Reducers */
import cart from './cart'
import token from './token'
import modal from './modal'
import favourites from './favourites'
import cities from './cities'
import city from './city'


/* Actions */
import { actionFavourites, actionCity } from './actions'


const store = configureStore({
    reducer: {
        cart, 
        token,
        modal,
        favourites,
        cities,
        city
    }
})


actionFavourites(store)
actionCity(store)

export { store }