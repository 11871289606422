import React, { Component } from "react"

/* Widgets */
import { CategoryProducts } from '../../widgets/Store'
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Categories
*/
class MobileCategories extends Component {

    render = () => {

        return (
            <div className="mobile-account-page">
                <CategoryProducts isMobile="yes" />
                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileCategories)