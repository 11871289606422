import axios from "axios"
import { env } from '../constants'


/* LIST */
export const list = token => {
    const uri = `${env.startpoint}address/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = (token, data) => {
    const uri = `${env.startpoint}address/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* EDIT */
export const edit = (token, data) => {
    const uri = `${env.startpoint}address/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* REMOVE */
export const remove = (token, data) => {
    const uri = `${env.startpoint}address/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
