import React, { Component } from "react"

/* Widgets */
import { Product } from '../Product'


/* Widget Top Products */
class Top extends Component {

    render = () => {

        const { data } = this.props

        return (
            <div className="top-products">

                <div className="home-title">
                    <h2>Топ товаров сегодня</h2>
                    <img src="/images/pseudo.png" alt="Pseudo" />
                    <p>Не упустите эту возможность со специальной скидкой только на этой неделе.</p>
                </div>

                <div className="top-products-box">
                    {data.map((item, index) => 
                        <div className="top-product-item" key={`${index}`}>
                            <Product data={item} />
                        </div>
                    )}
                </div>

            </div>
        )
    }

}

export default Top