import React, { Component } from "react"

import { withRouter } from '../../helpers'


/* Widget Slider */
class Slider extends Component {

    render = () => {

        const { navigate } = this.props

        return (
            <div className="slider">

                {/* BIG SLIDER */}
                <div className="big-slide">
                    <img src="/slides/big.png" alt="Slide" />
                    <div className="big-slide-inner">
                        <div className="big-slide-sale">
                            <span>Скидка дня</span>
                            <div className="big-slide-sale-percentage">-30%</div>
                        </div>
                        <h2>Оставайтесь дома и доставим вам</h2>
                        <p>Овощи содержат много витаминов и минералов, полезных для здоровья.</p>
                        <div onClick={() => navigate("/category/ovoschi-i-frukty")} className="big-slide-button">Купить сейчас</div>
                    </div>
                </div>

                {/* SMALL SLIDES */}
                <div className="slides">

                    <div onClick={() => navigate("/category/moloko-i-molochnye-produkty")} className="slide-item">
                        <img src="/slides/slide1.png" alt="Slide" />
                        <div className="slide-item-box">
                            <div className="slide-item-box-sale">СКИДКА 5%</div>
                            <p>Горячие предложения на новые товары</p>
                        </div>
                        <div className="slide-item-button">Купить сейчас</div>
                    </div>

                    <div onClick={() => navigate("/category/ovoschi-i-frukty")} className="slide-item">
                        <img src="/slides/slide2.png" alt="Slide" />
                        <div className="slide-item-box">
                            <div className="slide-item-box-sale">СКИДКА 5%</div>
                            <p>Покупайте больше и экономьте больше</p>
                        </div>
                        <div className="slide-item-button">Купить сейчас</div>
                    </div>

                    <div onClick={() => navigate("/category/myaso-i-moreprodukty")} className="slide-item">
                        <img src="/slides/slide3.png" alt="Slide" />
                        <div className="slide-item-box">
                            <div className="slide-item-box-sale">СКИДКА 5%</div>
                            <p>Готовое органическое мясо</p>
                        </div>
                        <div className="slide-item-button">Купить сейчас</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(Slider)