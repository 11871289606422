import React, { Component } from "react"

/* Components */
import { Loading, Network } from '../../components'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* JWT */
import { isExpired } from "react-jwt"

/* REST API */
import { get } from '../../api/User'
import { create as createAddress } from '../../api/Address'
import { create } from '../../api/Order'

/* Mask */
import InputMask from 'react-input-mask'

/* Modal */
import Modal from 'react-modal'

/* Swal */
import Swal from 'sweetalert2'


/* PAYMENT METHODS */
const CASH = "cash"
//const CARD = "card"
//const CARD_COURIES = "card_courier"

const PAYMENT_METHODS = [
    { key: CASH, text: "Наличными", disabled: false },
    /*{ key: CARD, text: "Картой на сайте", disabled: true },
    { key: CARD_COURIES, text: "Картой курьеру", disabled: true },*/
]


/* Widget Checkout */
class Checkout extends Component {

    constructor() {
        super()

        this.state = {
            name: '',
            phone: '',

            addresses: [],
            city: [],

            loading: true,
            network: false,
            error: false,

            deliveryType: 'delivery',
            comment: '',

            paymentMethod: CASH,

            addressID: 0,

            modal: false,

            addressName: '',
            address: '',
            cityID: 0,
            flat: '',
            floor: '',
            entrance: '',
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.load()
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }



    /* Load */
    load = () => {

        const { token, navigate, cart } = this.props

        if (cart.length === 0) {
            navigate('/')
            return
        }

        if (!token || isExpired(token)) {
            navigate('/')
            return
        }
        else {

            get(token).then(response => {
                if (response.status === 200) {
                    this.setState({
                        name: response.data.name ? response.data.name : "",
                        phone: response.data.phone ? response.data.phone : "",
                        addresses: response.data.address,
                        addressID: response.data.address.length > 0 ? response.data.address[0].id : 0,
                        city: response.data.city,
                        error: false,
                        network: false
                    })
                }
                else {
                    this.setState({ error: true })
                }
            }).catch(() => {
                this.setState({ error: true })
            }).finally(() => {
                this.setState({ loading: false })
            })

        }

    }


    /* Draw products */
    _products = () => {

        const { cart } = this.props
        const { deliveryType } = this.state

        let delivery = deliveryType === "delivery" ? 1000 : 0
        let total = 0

        cart.forEach(item => {
            const price = (item.salePrice && parseInt(item.salePrice) > 0) ? parseInt(item.salePrice) : parseInt(item.price)
            total += price * parseInt(item.amount)
        })

        if (total >= 20000) {
            delivery = 0
        }

        return (
            <div className="order-products">
                <h3>Состав заказа</h3>
                {cart.map((item, index) =>
                    <div key={`${index}`} className="order-product-row">
                        <p className="first">{item.name}</p>
                        <p>{item.amount} x {(item.salePrice && parseInt(item.salePrice) > 0) ? utils.convertor(item.salePrice) : utils.convertor(item.price)}</p>
                    </div>
                )}
                <div className="order-product-line" />
                <div className="order-product-row">
                    <p className="first">Доставка</p>
                    <p>{utils.convertor(delivery)}</p>
                </div>
                <div className="order-product-row">
                    <b>Итого</b>
                    <b>{utils.convertor(delivery + total)}</b>
                </div>
            </div>
        )

    }


    /* CREATE ADDRESS */
    createAddress = () => {

        const { addressName, address, cityID, flat, floor, entrance, addresses } = this.state
        const { token } = this.props

        if (addressName === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название адреса', showConfirmButton: false, timer: 1500 })
            return
        }

        if (address === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите адрес', showConfirmButton: false, timer: 1500 })
            return
        }

        if (cityID === null || cityID === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите город', showConfirmButton: false, timer: 1500 })
            return
        }

        const data = { name: addressName, address, cityID, flat, floor, entrance }


        createAddress(token, data).then(response => {
            if (response.status === 200) {

                if (addresses.length === 0) {
                    this.setState({ addressID: response.data.id })
                }

                addresses.push(response.data)

                this.setState({
                    addresses,
                    addressName: '',
                    address: '',
                    cityID: 0,
                    flat: '',
                    floor: '',
                    entrance: '',
                    modal: false
                })
                Swal.fire({ icon: 'success', title: 'Адрес успешно создан', showConfirmButton: false, timer: 1500 })
            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        })

    }


    order = () => {

        const { name, phone, addressID, comment, deliveryType, paymentMethod, addresses, width } = this.state
        const { cart, clear, token, navigate } = this.props


        if (name === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите свое имя', showConfirmButton: false, timer: 1500 })
            return
        }

        if (phone === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите телефон', showConfirmButton: false, timer: 1500 })
            return
        }

        if (deliveryType === "delivery" && parseInt(addressID) === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите адрес', showConfirmButton: false, timer: 1500 })
            return
        }

        const index = addresses.findIndex(a => parseInt(a.id) === parseInt(addressID))

        const addressName = index > -1 ? addresses[index].address : ''
        const flat = index > -1 ? addresses[index].flat : ''
        const floor = index > -1 ? addresses[index].floor : ''
        const entrance = index > -1 ? addresses[index].entrance : ''


        let delivery = deliveryType === "delivery" ? 1000 : 0
        let total = 0

        cart.forEach(item => {
            const price = (item.salePrice && parseInt(item.salePrice) > 0) ? parseInt(item.salePrice) : parseInt(item.price)
            total += price * parseInt(item.amount)
        })

        if (total >= 20000) {
            delivery = 0
        }


        const data = {
            name,
            phone,
            addressID: parseInt(addressID) > 0 ? addressID : null,
            comment,
            deliveryType,
            paymentMethod,
            products: cart,
            address: addressName,
            flat: flat,
            floor: floor,
            entrance: entrance,
            total: delivery + total,
            delivery
        }


        create(token, data).then(response => {
            if (response.status === 200) {
                clear()
                navigate(width > 768 ? `/my-orders` : `/account/orders`)
                Swal.fire({ icon: 'success', title: 'Заказ принят', showConfirmButton: false, timer: 1500 })
            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        })
    }


    /* Draw Address Modal */
    _address = () => {

        const { modal, city, addressName, address, cityID, flat, floor, entrance } = this.state

        return (
            <Modal
                isOpen={modal}
                onRequestClose={() => this.setState({ modal: false })}
                className="address-modal"
                overlayClassName="address-overlay"
            >

                <div onClick={() => this.setState({ modal: false })} className="address-modal-close">
                    <img src="/images/x.png" alt="X" />
                </div>

                <div className="address-modal-form">
                    <h2>Добавить адрес</h2>

                    <p>Название</p>
                    <input type="text" value={addressName} onChange={event => this.setState({ addressName: event.target.value })} placeholder="Введите название (Дом, Работа и т.д.)" />

                    <p>Город</p>
                    <select value={cityID} onChange={event => this.setState({ cityID: event.target.value })}>
                        <option disabled value={0}>Не выбран</option>
                        {city.map((item, index) =>
                            <option selected={parseInt(cityID) === parseInt(item.id)} key={`${index}`} value={item.id}>{item.name}</option>
                        )}
                    </select>

                    <p>Улица, дом</p>
                    <input type="text" value={address} onChange={event => this.setState({ address: event.target.value })} placeholder="Введите улицу и дом" />

                    <div className="address-modal-row">

                        <div className="address-modal-form-box">
                            <p>Квартира</p>
                            <input type="text" value={flat} onChange={event => this.setState({ flat: event.target.value })} placeholder="Номер квартиры" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Этаж</p>
                            <input type="text" value={floor} onChange={event => this.setState({ floor: event.target.value })} placeholder="Этаж" />
                        </div>

                        <div className="address-modal-form-box">
                            <p>Подъезд</p>
                            <input type="text" value={entrance} onChange={event => this.setState({ entrance: event.target.value })} placeholder="Подъезд" />
                        </div>

                    </div>

                    <div onClick={() => this.createAddress()} className="address-modal-button">
                        Сохранить
                    </div>
                </div>

            </Modal>
        )
    }


    render = () => {

        const { loading, network, error, name, phone, deliveryType, comment, paymentMethod, addresses, addressID } = this.state
        const { navigate } = this.props

        if (loading) {
            return <Loading />
        }

        if (error) {
            return <Network error reload={() => this.load()} />
        }

        if (network) {
            return <Network reload={() => this.load()} />
        }


        return (
            <div className="order">
                <div className="order-wrapper">

                    <div className="order-title">
                        <div onClick={() => navigate(-1)} className="order-back">
                            <img src="/images/back.png" alt="Back" />
                        </div>
                        <h2>Оформление заказа</h2>
                    </div>

                    <div className="order-box">

                        <div className="order-form">

                            <div className="order-form-box">
                                <h3>Личные данные</h3>

                                <div className="order-form-row">
                                    <p>Имя</p>
                                    <input type="text" value={name} onChange={event => this.setState({ name: event.target.value })} placeholder="Введите имя" />
                                </div>

                                <div className="order-form-row">
                                    <p>Номер телефона</p>
                                    <InputMask value={phone} onChange={event => this.setState({ phone: event.target.value })} mask="+7 (999) 999-99-99" placeholder="Введите номер телефона" />
                                </div>
                            </div>

                            <div className="order-form-box">
                                <h3>Способы доставки</h3>

                                <div className="order-delivery-types">
                                    <div onClick={() => this.setState({ deliveryType: 'delivery' })} className={deliveryType === 'delivery' ? `order-delivery-type-active` : 'order-delivery-type'}>Курьером</div>
                                    <div onClick={() => this.setState({ deliveryType: 'pickup' })} className={deliveryType === 'pickup' ? `order-delivery-type-active` : 'order-delivery-type'}>Самовывоз</div>
                                </div>

                                {deliveryType === 'delivery' &&
                                    <div className="order-form-row">
                                        <p>Адрес доставки</p>

                                        <div className="order-address">

                                            {addresses.map((item, index) =>
                                                <div onClick={() => this.setState({ addressID: item.id })} key={`${index}`} className="order-address-item">
                                                    {parseInt(addressID) === parseInt(item.id) ? <div className="checked"><div className="checked-inner" /></div> : <div className="unchecked" />}
                                                    <span>{item.address}</span>
                                                </div>
                                            )}

                                            <div onClick={() => this.setState({ modal: true })} className="order-address-add">Добавить адрес</div>

                                        </div>

                                    </div>
                                }

                                {deliveryType === 'delivery' && <div className="space" />}

                                <div className="order-form-row">
                                    <p>Комментарий к заказу</p>
                                    <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Ваше комментарии"></textarea>
                                </div>

                            </div>


                            <div className="order-form-box">
                                <h3>Способы доставки</h3>
                                <div className="order-payment-methods">
                                    {PAYMENT_METHODS.map((item, index) =>
                                        <div className={paymentMethod === item.key ? `payment-method` : `payment-method-disabled`} key={`${index}`}>
                                            {paymentMethod === item.key ? <div className="checked"><div className="checked-inner" /></div> : <div className="unchecked" />}
                                            <span>{item.text}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div onClick={() => this.order()} className="order-box-button">Потвердить и оформить заказ</div>

                        </div>

                        {this._products()}
                    </div>

                </div>

                {this._address()}


                <div className="order-box-mobile-bottom">
                    <div onClick={() => this.order()} className="order-box-mobile-button">Потвердить и оформить заказ</div>
                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}


const mapDispatchToProps = dispatch => {
    return {
        clear: () => dispatch({ type: 'CLEAR_CART', payload: [] })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Checkout))