import React, { Component } from "react"

/* JWT */
import { isExpired } from "react-jwt"

/* Redux */
import { connect } from 'react-redux'

/* REST API */
import { create as createFavourite, remove as removeFavourite } from '../../api/Favourite'


/* 
    Widget Heart
*/
class Heart extends Component {


    /* Favourite */
    favourite = () => {
        const { token, toggle, data, favourites, add, remove } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            const index = favourites.findIndex(e => parseInt(e.id) === parseInt(data.id))

            if (index > -1) {
                remove(data)
                removeFavourite(token, { productID: data.id })
            }
            else {
                add(data)
                createFavourite(token, { productID: data.id })
            }
        }
    }

    render = () => {

        const { data, favourites } = this.props
        const index = favourites.findIndex(e => parseInt(e.id) === parseInt(data.id))

        const classes = index > -1 ? "product-heart product-heart-active" : "product-heart"

        return (
            <div onClick={() => this.favourite()} className={classes}>
                <img src="/images/favourite.png" alt="Favourite" />
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        favourites: state.favourites,
        token: state.token
    }
}


const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({ type: 'ADD_FAVOURITE', payload: data }),
        remove: data => dispatch({ type: 'REMOVE_FAVOURITE', payload: data }),
        toggle: data => dispatch({ type: 'TOGGLE_MODAL', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Heart)
